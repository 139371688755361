import { Component, OnInit } from '@angular/core';
import { OrderService } from "../../services/order.service";

@Component({
  selector: 'app-get-free-followers',
  templateUrl: './get-free-followers.component.html',
  styleUrls: ['./get-free-followers.component.scss']
  //providers: [TiktokService]
})
export class GetFreeFollowersComponent implements OnInit {

  constructor(private _orderSer: OrderService) { }

  title = "50 Free TikTok Followers - Claim it Without Paying a Cent";
  sub_title1 = "There is an extraordinarily diversity of reasons someone would get free TikTok Followers. Moreover, although you might be thinking about jumping over the wagon and tempting of get free TikTok Followers and Fans right of the bat, most of the time it would be a wiser choice before you make a real purchase. So do not think twice, get 50 free Followers , there is No Sign - Up Needed, get it Free Instant!";
  sub_title2 = "";

  ngOnInit() {
    this._orderSer.package_id = 2;
  }

}
