import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TiktokService } from '../../shared/services/tiktok.service';

@Component({
  selector: 'app-og-ads-postback',
  templateUrl: './og-ads-postback.component.html',
  styleUrls: ['./og-ads-postback.component.scss']
})
export class OgAdsPostbackComponent implements OnInit {

  data: { order_id: any; offer_name: any; affiliate_id: any; session_ip: any; ip: any; date: any; payout: any; };

  constructor(private route: ActivatedRoute,
    private _ser: TiktokService,
    private router: Router) {

    this.data = {
      order_id: this.route.snapshot.queryParams.aff_sub4,
      offer_name: this.route.snapshot.queryParams.offer_name,
      affiliate_id: this.route.snapshot.queryParams.affiliate_id,
      session_ip: this.route.snapshot.queryParams.session_ip,
      ip: this.route.snapshot.queryParams.ip,
      date: this.route.snapshot.queryParams.date,
      payout: this.route.snapshot.queryParams.payout
    }
    // this.order_id = this.route.snapshot.queryParams.aff_sub;
    // this.offer_name = this.route.snapshot.queryParams.offer_name;
    // this.affiliate_id = this.route.snapshot.queryParams.affiliate_id;
    // this.source = this.route.snapshot.queryParams.source;
    // this.session_ip = this.route.snapshot.queryParams.session_ip;
    // this.ip = this.route.snapshot.queryParams.ip;
    // this.date = this.route.snapshot.queryParams.date;
    // this.payout = this.route.snapshot.queryParams.payout;
  }

  ngOnInit() {

    this._ser.setOgAdsPostbackData(this.data)
      .subscribe(res => {
        if (res.status === 200) {
          console.log('success setOgAdsPostbackData ');
          
        }

        this.router.navigate(['/'])
      });

  }

}
