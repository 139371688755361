import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Order } from "../../models/order";
import { catchError, mergeMap } from 'rxjs/operators';
import { throwError, Observable, of as ObservableOf, BehaviorSubject } from 'rxjs';
import { Package } from '../../models/package';
import { packagesArr } from '../../core/consts/package-list';


@Injectable({
  providedIn: 'root'
})
export class TiktokService {
  packages: Package[] = packagesArr;

  constructor(private _http: HttpClient) {
  }

  getFindUser(userName: string) {
    if (!userName)
      return;

    const url = environment.tiktokApiUrl + "getTikTokUser?username=" + userName;

    return this._http.get<any>(url);

  }

  getUserPosts(uid: any) {
    if (!uid)
      return;

    const url = environment.tiktokApiUrl + "getTikTokUserListPosts?uid=" + uid;

    return this._http.get<any>(url);
  }

  setOrder(order: Order) {
    if (!order || !order.user_id || !order.package_id)
      return;

    const url = environment.herokuApiUrl + "setorder";

    order.src = 2;
    return this._http.post<any>(url, order)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCheckoutSummary(order_id: number) {
    const url = environment.tiktokApiUrl + "checkout?order_id=" + order_id;

    return this._http.get<any>(url);

  }

  getPaymentLink(order_id: number) {
    const url = environment.tiktokApiUrl + "getPaymentLink";
    return this._http.post<any>(url, { order_id: order_id })
      .pipe(
        catchError(this.handleError)
      );
  }

  executePayment(paymentId, payerID) {
    const url = environment.tiktokApiUrl + "executePayment?paymentId=" + paymentId + "&payerID=" + payerID;
    return this._http.get<any>(url);
  }

  saveContactData(data: any) {
    const url = environment.tiktokApiUrl + "saveContactData";
    return this._http.post<any>(url, { data: data })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAdsList(orderId: number, device: string) {
    const params = new HttpParams()
      .set('orderId', orderId.toString())
      .set('device', device);

    const url = environment.tiktokApiUrl + "getAdsList";
    return this._http.get<any>(url, { params })
  }

  setOgAdsPostbackData(data: any) {
    const params = new HttpParams()
      .set('order_id', data.order_id)
      .set('offer_name', data.offer_name)
      .set('affiliate_id', data.affiliate_id)
      .set('session_ip', data.session_ip)
      .set('ip', data.ip)
      .set('date', data.date)
      .set('payout', data.payout);

    const url = environment.tiktokApiUrl + "ogAdsPostback";
    return this._http.get<any>(url, { params })
  }

  saveUserEmailAndName(data: any) {
    const params = new HttpParams()
      .set('order_id', data.order_id)
      .set('email', data.email);

    const url = environment.tiktokApiUrl + "saveUserEmailAndName";
    return this._http.get<any>(url, { params })
  }

  checkIfFreeOfferCompleted(order_id: number) {
    const params = new HttpParams()
      .set('order_id', order_id.toString());

    const url = environment.herokuApiUrl + "checkIfFreeOfferCompleted";
    return this._http.get<any>(url, { params })
  }

  getPackages(packageType: number): Package[] {
    if (!packageType)
      return [];

    const p = this.getPackagesByType(packageType);

    if (p && p.length > 0) {
      return p;
    } else {
      return [];
    }
  }

  private getPackagesByType(packTypeId: number): Package[] {
    if (this.packages && this.packages.length > 0) {
      return this.packages.filter(x => x.package_type == packTypeId);
    } else {
      return [];
    }
  }

  getCheckoutData(order_id: number) {
    const url = environment.herokuApiUrl + "checkoutUserData";
    return this._http.post<any>(url, { order_id: order_id })
        .pipe(
            catchError(this.handleError)
        );
}


getPaypalPaymentLink(params: string) {
  const url = environment.rootAPiUrl + "paypalCheckout" + `?params=${params}`;
  return this._http.get<any>(url)
      .pipe(
          catchError(this.handleError)
      );
}

getCryptoPaymentLink(params: string) {
  const url = environment.rootAPiUrl + "coinbaseCheckout" + `?params=${params}`;
  return this._http.get<any>(url)
      .pipe(
          catchError(this.handleError)
      );
}

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

}
