<!-- Page Top section -->
<section class="page-top-section set-bg">
  <div class="container">
    <h2>Contact</h2>
    <div class="text-white">
      <p class="sub-title-text">
        Home / Contact
      </p>
 
    </div>
  </div>
</section>
<!-- Page Top section end -->

<!-- Contact section -->
<section class="contact-section pb-5">

  <div class="container">

    <div *ngIf="isFormSent===true" class="alert alert-success" role="alert">
      Thanks for contacting us! We will be in touch with you shortly.
    </div>
    <div class="row">
      <div class="col-lg-3 contact-text">
        <h4>Contact Info</h4>
        <p>Our awesome customer support representatives are ready to answer any of your questions 24/7</p>
        <p>If you have any questions, feel free to leave your contact information and question below and our
          representatives will get back to you as soon as possible!</p>
      </div>
      <div class="col-lg-9" *ngIf="form!==null">
        <form [formGroup]="form" novalidate (ngSubmit)="onSubmit()" class="contact-form">
          <div class="row">
            <div class="col-md-6">
              <input class="form-control" formControlName="name" required type="text" placeholder="Your name"
                [ngClass]="{ 'is-invalid':  f.name.touched && f.name.errors }">
              <div *ngIf="f.name.errors!==null && f.name.touched===true" class="invalid-feedback">
                <div *ngIf="f.name.errors.required===true">Name is required</div>
              </div>
            </div>
            <div class="col-md-6">
              <input class="form-control" formControlName="email" required type="email"
                [ngClass]="{ 'is-invalid':  f.email.touched && f.email.errors }" placeholder="Your e-mail">
              <div *ngIf="f.email.errors!==null  && f.email.touched===true" class="invalid-feedback">
                <div *ngIf="f.email.errors.required===true">Email is required</div>
                <div *ngIf="f.email.errors.email!==null">Email must be a valid email address</div>
              </div>
            </div>
            <div class="col-md-12">
              <input class="form-control" formControlName="subject" required type="text"
                [ngClass]="{ 'is-invalid':  f.subject.touched && f.subject.errors }" placeholder="Subject">
              <div *ngIf="f.subject.errors!==null  && f.subject.touched===true" class="invalid-feedback">
                <div *ngIf="f.subject.errors.required===true">Subject is required</div>
              </div>
              <textarea class="form-control" formControlName="msg" required placeholder="Message"
                [ngClass]="{ 'is-invalid':  f.msg.touched && f.msg.errors }"></textarea>
              <div *ngIf="f.msg.errors!==null  && f.msg.touched===true" class="invalid-feedback">
                <div *ngIf="f.msg.errors.required===true">Message is required</div>
              </div>
              <button [disabled]="!form.valid" class="mt-4 btn btn-primary btn-lg btn-radius"
                type="submit">Send</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</section>
<!-- Contact section end -->