<!-- Hero section -->
<section class="hero-section set-bg  " data-setbg="assets/ithost-template/img/bg.jpg">
  <div class="container h-100">
    <div class="hero-content text-white ">
      <div class="row">
        <div class="col-lg-6 pr-0">
          <h1>TikTok Likes {{currentYear}}</h1>
          <p>In the limitless cyber world, you will find many websites letting you think they can provide you TikTok
            Likes and Fans. But how many can really provide you a service? Probably non. Thankfully, we created a stable
            and reputable service for you to enjoy - TikTokBlaster. </p>

          <div class="container">
            <div class="row ">
              <div class="align-self-center mb-4  ml-3">
                <a routerLink="/free-tiktok-likes" title="50 Free Likes">
                  <div class="title-container">
                    <div class="heart-container">
                      <i class="fas fa-heart"></i>
                    </div>
                    <div class="plan-title">
                      <div>
                        <h5>50 Free Likes</h5>
                      </div>
                    </div>
                  </div>
                </a></div>

              <div class="align-self-center mb-4 ml-3">
                <a routerLink="/free-tiktok-followers" title="50 Free followers">
                  <div class="title-container">
                    <div class="heart-container">
                      <i class="fas fa-user-plus"></i>
                    </div>
                    <div class="plan-title">
                      <div>
                        <h5>50 Free followers</h5>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="align-self-center mb-4  ml-3">
                <a routerLink="/buy-likes" title="Buy TikTok Likes">
                  <div class="title-container">
                    <div class="heart-container">
                      <i class="fas fa-heart"></i>
                    </div>
                    <div class="plan-title">
                      <div>
                        <h5>Buy Likes</h5>
                      </div>
                    </div>
                  </div>
                </a></div>

              <div class="align-self-center mb-4 ml-3">
                <a routerLink="/buy-followers" title="Buy TikTok Followers">
                  <div class="title-container">
                    <div class="heart-container">
                      <i class="fas fa-user-plus"></i>
                    </div>
                    <div class="plan-title">
                      <div>
                        <h5>Buy Followers</h5>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-rocket">
        <img src="assets/ithost-template/img/rocket.png" alt="">
      </div>
    </div>
  </div>
</section>
<!-- Hero section end -->


<app-how-it-works></app-how-it-works>

<!-- Carousel -->
<section>
  <div class="container">
    <div class="section-title">
      <img class="section-title-icon" alt="#">
      <h2>What Our Customers Have to Say about us</h2>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-8 carousel-container">

        <ngb-carousel>
          <ng-template ngbSlide>
            <div class="testimonial-item">
              <div class="ti-content">
                <p>I always had a dream to be famous. Now with TikTokBlaster I AM! </p>
              </div>
              <div class="ti-author-pic">
                <img class="author-pic team-pic-1">
              </div>
              <div class="ti-author">
                <h6>Daniel Smith</h6>
                <span>Artist</span>
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide>
            <div class="testimonial-item">
              <div class="ti-content">
                <p>Thank you so much, i shared your service with 3 of my friends and we all loved it, you are the best!
                </p>
              </div>
              <div class="ti-author-pic">
                <img class="author-pic team-pic-2">
              </div>
              <div class="ti-author">
                <h6>Maria Williams</h6>
                <span>Student</span>
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide>
            <div class="testimonial-item">
              <div class="ti-content">
                <p>I have been looking for this kind of service for months! Honestly, i tested over 5 websites and non
                  of them provided what they promised, but you did :) </p>
              </div>
              <div class="ti-author-pic">
                <img class="author-pic team-pic-3">
              </div>
              <div class="ti-author">
                <h6>Michael Mueller</h6>
                <span>Writer</span>
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide>
            <div class="testimonial-item">
              <div class="ti-content">
                <p>My daughter always wanted to be a celebrity. She just turned 14, and i had no question of what will
                  be her present this year, 10,000 fans and 6000 likes in total!</p>
              </div>
              <div class="ti-author-pic">
                <img class="author-pic team-pic-4">
              </div>
              <div class="ti-author">
                <h6>Jane Flores</h6>
                <span>Singer</span>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>

      </div>
    </div>
  </div>
</section>
<!-- Carousel -->

<!-- Company Expert section-->
<section class="about-section spad pt-0">
  <div class="container">
    <div class="section-title">
      <img class="section-title-icon" alt="Our TikTok Experts Team">
      <h2>Our TikTok Experts Team</h2>
    </div>
    <div class="row">
      <div class="col-lg-7 about-text">
        <h3>Our TikTok Likes And Fans Experts Has Got Everything Ready Just For You</h3>

        <p>Luckily for you, our team of tikTok likes and fans experts are here to support your needs. Our team has
          gathered the requiered knowledge and know how about TikTok likes and fans. </p>
        <p>With half a century experience in the field under our belt, we can confidently announce and guide you through
          the right path to become a bright star in the world. </p>
        <p>You should never ever pick the first TikTok likes or fans web you see, this is the actual the reason we were
          created. So whenever you're searching online for TikTok fans or likes always keep in mind, we are committed
          and truly devoted providing you all that you need to reach your profile goal. </p>

      </div>
      <div class="col-lg-5">
        <img class="img-company-expert" alt="Our TikTok likes and fans experts team">
      </div>
    </div>
  </div>
</section>
<!-- Company Expert  section end -->