<!-- Page Top section -->
<section class="page-top-section set-bg">
  <div class="container">
    <h2>{{title}}</h2>
    <div class="text-white">
      <p class="sub-title-text">{{sub_title1}}</p>

      <p class="sub-title-text">{{sub_title2}}</p>

    </div>
  </div>
</section>
<!-- Page Top section end -->