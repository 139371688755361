import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor() { }
  title = "Frequently Answered Question";
  sub_title1 = "Got a question for TikTiok Blaster experts team? Check below to see if it's been answered.";
  sub_title2 = "";

  ngOnInit() {
  }

}
