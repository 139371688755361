<!-- Header section -->
<header class="header-section">
  <div class="site-logo">
    <img src="/assets/ithost-template/img/logo.png" alt=""></div>
</header>
<!-- Header section end -->

<!-- Page Top section -->
<section class="page-top-section set-bg">
  <div class="container text-center">
    <h2 *ngIf="package_id>0" i18n="free offers|Title">Congratulations!</h2>
    <div class="text-white text-center">
      <p class="sub-title-text">
        <!-- Claim your 50 Likes! It's Easy As 1-2-3! -->
      </p>
      <p class="sub-title-text" i18n="free offers instuction|sub-itle">Complete <b><u>Only One</u></b>
        of the offers below and <b>Receive
          {{actionDescription[package_id]}}
          - Instantly!</b></p>

    </div>
  </div>
</section>
<!-- Page Top section end -->

<div class="main-container">
  <div class="container " *ngIf="offers!==null && offers.length>0">
    <div class="mr-1">

      <div *ngFor="let item of offers">
        <a angulartics2On="click" angularticsAction="OGAdsLinkClicked" angularticsLabel="ogads-link-clicked"
          angularticsCategory="OGAds" [angularticsProperties]="{'link-name': item.name}" [href]="item.link"
          target="_blank" class="lnkClaimOffer" (click)="checkIfFreeOfferCompleted()">
          <div class="row justify-content-center align-items-center bottom-border pt-2">
            <div class="img-container">
              <img [src]="item.picture" [alt]="item.name" />
            </div>
            <div class="col">
              <div class="row">
                <h5 class="text-primary font-weight-bold">
                  <u>{{item.name}}</u>
                </h5>
              </div>

              <div class="row">
                <span class="action-text"><b>Action: </b> {{item.adcopy}}</span>
              </div>

              <div class="row pt-1">
                <span class="badge badge-success" i18n="free offers offer level|badge">Easy</span>
              </div>
            </div>
            <div class="row align-items-center ml-1 mr-1 ">
              <div class="col pl-1 ">
                <div role="button" class="btn btn-primary btn-md btn-radius lnkClaimOffer"
                  i18n="free offers go button|go">
                  Go <i class="fas fa-chevron-right"></i></div>
              </div>
            </div>
          </div>
        </a>
      </div>

      <section class="faq-section pt-1">
        <div class="container">
          <div class="section-title">
            <img class="section-title-icon" alt="TikTok likes and followers faq">
            <h2>Ready To take your TikTok to the next level?</h2>
          </div>

          <div class="row justify-content-center mt-4 text-center">
            <div class="col-sm-3 align-self-center mb-4">
              <button routerLink="/buy-likes" type="button" class="btn btn-primary btn-lg btn-radius">
                Buy TikTok Likes <br> Starting from $2.99</button>
            </div>

            <div class="col-sm-3 align-self-center mb-4">
              <button routerLink="/buy-followers" type="button" class="btn btn-primary btn-lg btn-radius">
                Buy TikTok Fans <br> Starting from $2.99</button>
            </div>
          </div>

        </div>
      </section>
    </div>
  </div>

  <div *ngIf="offersFound ===false" class="container pt-5">
    <div class="section-title">
      <img class="section-title-icon" alt="TikTok likes and followers faq">
      <h2>No Free offers were found</h2>
      <h5 class=" text-primary font-weight-bold mb-2 mt-4">You can always buy one of our
        likes and followers viral packages</h5>
    </div>

    <div class="row justify-content-center mt-4 text-center">
      <div class="col-sm-3 align-self-center mb-4">
        <button routerLink="/buy-likes" type="button" class="btn btn-primary btn-lg btn-radius">
          Buy TikTok Likes <br> Starting from $3.99</button>
      </div>

      <div class="col-sm-3 align-self-center mb-4">
        <button routerLink="/buy-followers" type="button" class="btn btn-primary btn-lg btn-radius">
          Buy TikTok Fans <br> Starting from $3.99</button>
      </div>
    </div>

    <div class="row justify-content-center mt-4 text-center">
      <div class="col-sm-3 align-self-center mb-4">
        <button href="https://instblast.com/tiktok/buy-tiktok-views" type="button" class="btn btn-primary btn-lg btn-radius">
          Buy TikTok Views <br> Starting from $0.99</button>
      </div>

      <div class="col-sm-3 align-self-center mb-4">
        <button href="https://instblast.com/tiktok/free-tiktok-views" type="button" class="btn btn-primary btn-lg btn-radius">
          Free TikTok Views <br> Get Free 1000 views</button>
      </div>
    </div>

  </div>

</div>


<!-- Footer section -->
<footer class="footer-section">
  <div class="container-fluid">

    <div class="copyright">
      Copyright &copy;
      <script>document.write(new Date().getFullYear());</script> All rights reserved to TikTok Blaster
    </div>
  </div>
</footer>
<!-- Footer top section end -->