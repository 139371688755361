import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-page-top-section',
  templateUrl: './page-top-section.component.html',
  styleUrls: ['./page-top-section.component.scss']
})
export class PageTopSectionComponent implements OnInit {
  @Input() title: string;
  @Input() sub_title1: string;
  @Input() sub_title2: string;
  constructor() { }

  ngOnInit() {
  }

}
