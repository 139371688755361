import { NgModule } from '@angular/core';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { Routes, RouterModule } from '@angular/router';
import { CoreModule } from '../../core/core.module';

const routes: Routes = [
  { path: '', component: TermsOfServiceComponent }
];

@NgModule({
  declarations: [TermsOfServiceComponent],
  imports: [
    CoreModule,
    RouterModule.forChild(routes)
  ]
})
export class TermsOfServiceModule { }
