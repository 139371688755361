import { Component, OnInit } from '@angular/core';
import { TiktokService } from '../../../shared/services/tiktok.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isNavbarCollapsed = false;

  

  constructor(private _ser: TiktokService) { }
 
  ngOnInit() {

  }

}
