import { Component, OnInit } from '@angular/core';
import { TiktokService } from '../../../shared/services/tiktok.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(private _ser: TiktokService) { }

  ngOnInit() {
  }

}
