import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GetFreeLikesComponent } from './modules/get-free-likes/get-free-likes.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgbCarouselModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './modules/home/home.component';
import { CheckoutComponent } from './modules/checkout/checkout.component';
import { PaymentSuccessComponent } from './modules/payment-success/payment-success.component';
import { PaymentExcecuteComponent } from './modules/payment-excecute/payment-excecute.component';
import { GetFreeFollowersComponent } from './modules/get-free-followers/get-free-followers.component';
import { FaqComponent } from './modules/faq/faq.component';
import { ContactComponent } from './modules/contact/contact.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FreeOffersComponent } from './modules/free-offers/free-offers.component';
import { OgAdsPostbackComponent } from './modules/og-ads-postback/og-ads-postback.component';
import { BlogComponent } from './modules/blog/blog.component';
import { PrivacyModule } from './modules/privacy/privacy.module';
import { TermsOfServiceModule } from './modules/terms-of-service/terms-of-service.module';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

@NgModule({
  declarations: [
    AppComponent,
    GetFreeLikesComponent,
    HomeComponent,
    CheckoutComponent,
    PaymentSuccessComponent,
    PaymentExcecuteComponent,
    GetFreeFollowersComponent,
    FaqComponent,
    ContactComponent,
    FreeOffersComponent,
    OgAdsPostbackComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    NgbAccordionModule, NgbCarouselModule,
    CoreModule,
    DeviceDetectorModule.forRoot(),
    BrowserAnimationsModule,
    PrivacyModule,
    TermsOfServiceModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        excludedRoutes: [
          'payment-excecute',
          'buy',
          'checkout',
          'success'
        ],
      }
    })
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
