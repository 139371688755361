export interface Package {
    id: number;
    package_type: number;
    amount: number;
    price: number;
    old_price?: number,
    description: string;
}

export function initPackage(): Package {
    return { amount: 0, description: '', price: 0, package_type: 0, id: 0 };
}