<app-page-top-section [title]="title" [sub_title1]="sub_title1" [sub_title2]="sub_title2"></app-page-top-section>


<app-user-order></app-user-order>


<!-- Milestones section -->
<section class="milestones-section set-bg-milestones">
    <div class="container">
        <div class="section-title">
            <img class="section-title-icon" alt="Free TikTok Likes service">
            <h2 style="color:white">
                Results are a click away with your Top Free TikTok Likes service
            </h2>
        </div>
        <div class="row milestones-top-text">
            <div class="col-lg-10">
                <p>Some would say that adding TikTok likes is for the younger audience only. But what a similar outcome
                    will take over all ages when they will get feedback from friends and family about a likeable post.
                </p>
                <p>
                    In fact, it will gain tremendous effect on your mood, not matter who you are. Getting free TikTok
                    likes or fans will enable you to gain knowledge about yourself before moving forward to the real
                    fun.
                </p>
            </div>
        </div>
    </div>
</section>
<!-- Milestones section end -->

<!-- Company Expert section-->
<section class="about-section spad pt-5">
    <div class="container">
        <div style="text-align: center;">
            <img class="section-title-icon" alt="Getting your Free TikTok Like will">
            <h2>Getting your Free TikTok Like will:</h2>
        </div>
        <div class="row">
            <div class="col-lg-12 about-text pt-4" style="padding: 0 20px 0 20px">
                <ol>
                    <li class="bullet-text">Let you learn about your own reaction to likes.</li>
                    <li class="bullet-text">Enables you to understand more about your closest reactions and feedback.
                    </li>
                    <li class="bullet-text">Allows you to test the service before trying it for real.</li>
                    <li class="bullet-text">Grant you the possibility to see what your family has to say.</li>
                    <li class="bullet-text">Helps you better your image among your loved once.</li>
                    <li class="bullet-text">Allows you to feel comfortable to move forward to the next level</li>
                </ol>
            </div>

        </div>

        <div class="pt-5">

            <div style="text-align: center;">
                <img class="section-title-icon" alt="Getting your Free TikTok Like will">
                <h2>The best things are Free</h2>
            </div>

            <p class="bullet-text pt-2">
                As most people out there have to agree, "The best things are free". At the same time our experts at
                TikTokBlasters don't want to look as if they are cheap, we can be absolutely certain that trying a
                service our for free is essential to see what it is really worth.
            </p>
        </div>
    </div>
</section>
<!-- Company Expert  section end -->