import { Component, OnInit } from '@angular/core';
import { OrderService } from "../../services/order.service";

@Component({
  selector: 'app-get-free-likes',
  templateUrl: './get-free-likes.component.html',
  styleUrls: ['./get-free-likes.component.scss']
})

export class GetFreeLikesComponent implements OnInit {

  title = "50 Free TikTok Likes - Claim it Without Paying a Cent";
  sub_title1 = "There is an extraordinarily diversity of reasons someone would get free TikTok likes. Moreover, although you might be thinking about jumping over the wagon and tempting of get free TikTok likes right of the bat, most of the time it would be a wiser choice before you make a real purchase. So do not think twice, get 75 free likes, there is No Sign - Up Needed, get it Free Instant!";
  sub_title2 = "";

  constructor(private _orderSer: OrderService) { }

  ngOnInit() {
    this._orderSer.package_id = 1;
  }
}
