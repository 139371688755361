<!-- Features section -->
<section class="features-section spad">
  <div class="container">
    <div class="section-title">
      <img class="section-title-icon" alt="#">
      <h2 i18n="how it works title|Title">How It Works</h2>
      <p i18n="how it works sub-title|subtitle">Getting started with TikTokBlaster is easy – it only takes a few
        minutes!</p>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 feature-item">
        <div class="ft-icon">
          <i class="icons guaranteed-icon"></i>
        </div>
        <h4 i18n="how it works item title|Item title">Guaranteed Results</h4>
        <p i18n="how it works item description|Item descroption">We do not play games, unlike other websites, when we
          promise, we deliver. Give us a test drive and you won't
          be disappointed.</p>
      </div>
      <div class="col-lg-4 col-md-6 feature-item fi-center-top">
        <div class="ft-icon">
          <i class="icons no-pass-icon"></i>
        </div>
        <h4 i18n="how it works second item title|Item title">No Password Required</h4>
        <p i18n="how it works second item description|Item descroption">You are safe! We do not need your account
          password to deliver you the results. Just submit your TikTok
          username and follow the steps.</p>
      </div>
      <div class="col-lg-4 col-md-6 feature-item">
        <div class="ft-icon">
          <i class="icons security-icon"></i>
        </div>
        <h4 i18n="how it works item title|Item title">Security</h4>
        <p i18n="how it works item description|Item descroption">Our website uses SSL certificate which includes top encryption. Your personal details are secured.</p>
      </div>
      <div class="col-lg-4 col-md-6 feature-item">
        <div class="ft-icon">
          <i class="icons support-icon"></i>
        </div>
        <h4 i18n="how it works item title|Item title">24/7 Support</h4>
        <p i18n="how it works item description|Item descroption">We are always available to give you the best customer service to make sure you are happy with our service.
          Feel free to contact us.</p>
      </div>
      <div class="col-lg-4 col-md-6 feature-item">
        <div class="ft-icon">
          <i class="icons fans-icon"></i>
        </div>
        <h4 i18n="how it works item title|Item title">Free Likes & Fans</h4>
        <p i18n="how it works item description|Item descroption">Try us out with free likes or fans before you make a purchase. Unlike others, we will deliver them with
          love.</p>
      </div>
      <div class="col-lg-4 col-md-6 feature-item">
        <div class="ft-icon">
          <i class="icons delivery-icon"></i>
        </div>
        <h4  i18n="how it works item title|Item title">Quick Delivery</h4>
        <p i18n="how it works item description|Item descroption">Your likes or fans will reach their destination in between one hour to 24 hours max.</p>
      </div>
    </div>
  </div>
</section>
<!-- Features section end -->