import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  constructor() { }
  title = "TikTok Blaster blog";
  sub_title1 = "TikTok Common Featured and Trends";
  sub_title2 = "";

  ngOnInit() {
  }

}
