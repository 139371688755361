<app-page-top-section [title]="title"></app-page-top-section>
<div class="container">
  <p>By purchasing any of the services from www.tiktokblaster.com you must first agree to the following terms of service
    at the checkout page. The terms of service are as follows:</p>
  <p>&nbsp;</p>
  <p><strong>Refund Policy:</strong><br /> If you are not 100% satisfied with your purchase, within 30 days from the
    purchase date, we will fully refund the cost of your order no questions asked.</p>
  <p>Should we fail to respond to a refund request via email ( this is usually in the event that we have not got your
    email) then please feel free to open a dispute in paypal and we will fully refund your money this way.</p>
  <p>&nbsp;</p>
  <p><strong>Lost Likes Policy:</strong><br /> We always over deliver all of our services. If the amount of likes should drop below the amount purchased within 30 days of
    purchase then we will either replace the lost amount of likes or if you are within 30 days of purchase a refund will
    be given on request as explained above.</p>
  <p>&nbsp;</p>
  <p><strong>Responsibility<strong>:</strong></strong><br /> We do not have any control over any third party&rsquo;s
    action as a result of any purchases made on this website.</p>
  <p>&nbsp;</p>
  <p><strong>Damages and Losses:</strong></p>
  <p>We can not be held responsible for any damages or losses as a result of purchasing any of the services on this
    website.</p>
  <p>&nbsp;</p>
  <p><strong>Affiliation:</strong><br /> We are in no way affiliated with TikTok.</p>
</div>