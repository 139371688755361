import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GetFreeLikesComponent } from './modules/get-free-likes/get-free-likes.component';
import { HomeComponent } from './modules/home/home.component';
import { BuyFollowersComponent } from './modules/buy-followers/buy-followers.component';
import { CheckoutComponent } from './modules/checkout/checkout.component';
import { PaymentSuccessComponent } from './modules/payment-success/payment-success.component';
import { PaymentExcecuteComponent } from './modules/payment-excecute/payment-excecute.component';
import { GetFreeFollowersComponent } from './modules/get-free-followers/get-free-followers.component';
import { FaqComponent } from './modules/faq/faq.component';
import { ContactComponent } from './modules/contact/contact.component';
import { FreeOffersComponent } from './modules/free-offers/free-offers.component';
import { OgAdsPostbackComponent } from './modules/og-ads-postback/og-ads-postback.component';
import { BlogComponent } from './modules/blog/blog.component';
import { SiteLayoutComponent } from './core/components/site-layout/site-layout.component';

const routes: Routes = [

  //Site routes goes here 
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'free-tiktok-likes', component: GetFreeLikesComponent },
      { path: 'free-tiktok-followers', component: GetFreeFollowersComponent },
      { path: 'checkout', component: CheckoutComponent },
      { path: 'success', component: PaymentSuccessComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'contact', component: ContactComponent },

      { path: 'ogAdsPostbackUrl', component: OgAdsPostbackComponent },
      { path: 'blog', component: BlogComponent },
      {
        path: 'buy-followers',
        loadChildren: './modules/buy-followers/buy-followers.module#BuyFollowersModule'
      },
      {
        path: 'buy-likes',
        loadChildren: './modules/buy-likes/buy-likes.module#BuyLikesModule'
      },
      {
        path: 'privacy',
        loadChildren: './modules/privacy/privacy.module#PrivacyModule'
      },
      {
        path: 'terms-of-service',
        loadChildren: './modules/terms-of-service/terms-of-service.module#TermsOfServiceModule'
      }

    ]
  },

  { path: 'free-offers', component: FreeOffersComponent },
  //no layout routes
  {
    path: 'free-likes-offer',
    loadChildren: './modules/free-likes-landing-page/free-likes-landing-page.module#FreeLikesLandingPageModule'
  },
  {
    path: 'free-fans-offer',
    loadChildren: './modules/free-followers-landing-page/free-followers-landing-page.module#FreeFollowersLandingPageModule'
  },
  {
    path: 'free-premium-likes',
    loadChildren: './modules/free-premium-likes/free-premium-likes.module#FreePremiumLikesModule'
  },
  {
    path: 'aw-redirect',
    loadChildren: './modules/aw-redirect/aw-redirect.module#AwRedirectModule'
  },
  { path: 'payment-excecute', component: PaymentExcecuteComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
