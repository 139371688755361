import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { LikesPricingComponent } from './components/likes-pricing/likes-pricing.component';
import { FollowersPricingComponent } from './components/followers-pricing/followers-pricing.component';
import { PageTopSectionComponent } from './components/page-top-section/page-top-section.component';
import { LikesPriceMobileComponent } from './components/likes-price-mobile/likes-price-mobile.component';
import { SiteLayoutComponent } from './components/site-layout/site-layout.component';
import { UserOrderComponent } from './components/user-order/user-order.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { OrderService } from '../services/order.service';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { FollowersPriceMobileComponent } from './components/followers-price-mobile/followers-price-mobile.component';

export const COMPONENTS = [
  HeaderComponent, FooterComponent, LikesPricingComponent, FollowersPricingComponent,
  PageTopSectionComponent, LikesPriceMobileComponent, SiteLayoutComponent, UserOrderComponent,
  HowItWorksComponent, FollowersPriceMobileComponent
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule
  ],
  exports: [COMPONENTS,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule]
})
export class CoreModule { }
