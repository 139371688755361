import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-likes-pricing',
  templateUrl: './likes-pricing.component.html',
  styleUrls: ['./likes-pricing.component.scss']
})
export class LikesPricingComponent implements OnInit {
  @Output() packageIdEmitter = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }

  setPackageId(packageId: number) {
    this.packageIdEmitter.emit(packageId);
  }
}
