import { Injectable } from '@angular/core';
import { Order } from '../models/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private _order_id?: number;
  private _user_id?: string;
  private _user_name?: string;
  private _email?: string;
  private _aweme_id?: string;
  private _package_id?: number;

  constructor() {
    this.initOrderFromLocalStorage();
  }

  get order(): Order {
    return {
      order_id: this._order_id,
      user_id: this._user_id,
      user_name: this._user_name,
      aweme_id: this._aweme_id,
      email: this._email,
      package_id: this._package_id
    }
  }

  set order(ord: Order) {
    this._aweme_id = ord.aweme_id;
    this._email = ord.email;
    this._order_id = ord.order_id;
    this._user_name = ord.user_name;
    this._package_id = ord.package_id;
    this._user_id = ord.user_id

  }

  get order_id() {
    return this._order_id;
  }

  get package_id() {
    return this._package_id;
  }

  get aweme_id() {
    return this._aweme_id;
  }

  set package_id(id) {
    this._package_id = id;
    this.setOrderToLocalStorage();
  }

  set aweme_id(video) {
    this._aweme_id = video;
  }

  set user_id(id) {
    this._user_id = id;
    this.setOrderToLocalStorage();
  }

  set user_name(name) {
    this._user_name = name;
    this.setOrderToLocalStorage();
  }

  set email(email) {
    this._email = email;
    this.setOrderToLocalStorage();
  }

  set order_id(id) {
    this._order_id = id;
    this.setOrderToLocalStorage();
  }

  private setOrderToLocalStorage() {
    if (window.localStorage) {
      // localStorage supported
      localStorage.setItem("order", JSON.stringify(this.order));
    }
  }

  private initOrderFromLocalStorage() {
    if (window.localStorage) {
      // localStorage supported
      const data = localStorage.getItem('order');
      if (data) {
        this.order = JSON.parse(data);
      } else {
        this.order = {};
      }
    }
    else {
      this.order = {};
    }
  }

}
