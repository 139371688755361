import { Injectable } from '@angular/core';
import { initPackage, Package } from '../../models/package';

@Injectable({
  providedIn: 'root'
})
export class PackageDetailsService {
  private _selectedPack: Package = initPackage();
  constructor() { this.initPackFromLocalStorage() }

  initPack() {
    this.package = initPackage();
    this.setOrderToLocalStorage();
  }

  get package(): Package {
    return this._selectedPack;
  }

  set package(pack: Package) {
    this._selectedPack = pack;
    this.setOrderToLocalStorage();
  }

  private setOrderToLocalStorage() {
    if (window.localStorage && this.package) {
      localStorage.setItem("package", JSON.stringify(this.package));
    }
  }

  private initPackFromLocalStorage() {
    if (window.localStorage) {
      // localStorage supported
      const data = localStorage.getItem('package');
      if (data) {
        this.package = JSON.parse(data);
      } else {
        this.package = initPackage();
      }
    }
    else {
      this.package = initPackage();
    }
  }
}
