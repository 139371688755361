<!-- Footer section -->
<footer class="footer-section">
  <div class="container-fluid">
    <ul class="footer-menu">
      <li><a routerLink="/free-tiktok-likes">Get Free TikTok Likes</a>
      </li>
      <li><a href="https://instblast.com/tiktok/likes/buy-tiktok-likes" target="_blank">Buy TikTok Likes</a></li>
      <li><a routerLink="/free-tiktok-followers">Get Free TikTok
          Followers</a></li>
      <li><a href="https://instblast.com/tiktok/followers/buy-tiktok-followers" target="_blank">Buy TikTok Followers</a></li>
      <li ><a href="https://instblast.com/tiktok/views/buy-tiktok-views" target="_blank">Buy TikTok Views</a></li>
      <li ><a href="https://instblast.com/tiktok/views/free-tiktok-views" target="_blank">Free TikTok Views</a></li>
      <li><a routerLink="/faq">FAQ</a></li>
      <li><a routerLink="/contact">Contact us</a></li>
      <li><a routerLink="/blog">Blog</a></li>
      <li><a routerLink="/terms-of-service">Terms of Service</a></li>
      <li><a routerLink="/privacy">Privacy Policy</a></li>
    </ul>
    <div class="copyright">
      Copyright &copy;
      <script>document.write(new Date().getFullYear());</script> All rights reserved to TikTok Blaster
    </div>
  </div>
</footer>
<!-- Footer top section end -->