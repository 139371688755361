<app-page-top-section [title]="title" [sub_title1]="sub_title1" [sub_title2]="sub_title2"></app-page-top-section>

<div class="container main-container ">
  <div class="alert alert-success" role="alert">
    All aboard! Your payment has been processed.
    <p>We are working on your order </p>
    <div class="pt-2">
      <a routerLink="/" class=" ">
        Return home</a>
    </div>
  </div>

  <div class="row ">
    <div class="col align-self-center mb-4">
      <a routerLink="/free-tiktok-likes" class="btn btn-primary btn-lg btn-radius">
        Get 50 more Free Likes</a>
    </div>

    <div class="col align-self-center mb-4">
      <button routerLink="/free-tiktok-followers" type="button" class="btn btn-primary btn-lg btn-radius">
        Get 50 more Free Followers</button>
    </div>
  </div>

  <div class="row">
    <div class="col mb-4">
      <button routerLink="/buy-likes" type="button" class="btn btn-primary btn-lg btn-radius">
        Buy more Likes</button>
    </div>
    <div class="col mb-4">
      <button routerLink="/buy-followers" type="button" class="btn btn-primary btn-lg btn-radius">
        Buy more Followers</button>
    </div>
  </div>

</div>