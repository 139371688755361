<!-- Header section -->
<header class="header-section">
  <a routerLink="/" class="site-logo" (click)="isNavbarCollapsed= false">
    <img src="assets/ithost-template/img/logo.png" alt=""></a>
  <div class="nav-switch" (click)="isNavbarCollapsed= !isNavbarCollapsed">
    <i class="fa fa-bars"></i>
  </div>
  <div class="nav-warp" [class.expand-menu]="isNavbarCollapsed">
    <ul class="main-menu">
      <li><a routerLink="/free-tiktok-likes" (click)="isNavbarCollapsed= !isNavbarCollapsed">Free TikTok Likes</a>
      </li>
      <li ><a routerLink="/buy-likes" (click)="isNavbarCollapsed= !isNavbarCollapsed">Buy
          TikTok Likes</a></li>
      <li><a routerLink="/free-tiktok-followers" (click)="isNavbarCollapsed= !isNavbarCollapsed">Free TikTok
          Followers</a></li>
      <li ><a routerLink="/buy-followers"
          (click)="isNavbarCollapsed= !isNavbarCollapsed">Buy TikTok Followers</a></li>
      <li ><a routerLink="/faq"
          (click)="isNavbarCollapsed= !isNavbarCollapsed">FAQ</a></li>
      <li ><a routerLink="/contact"
          (click)="isNavbarCollapsed= !isNavbarCollapsed">Contact us</a></li>
      <li ><a routerLink="/blog" (click)="isNavbarCollapsed= !isNavbarCollapsed">Blog</a></li>
    </ul>
  </div>
</header>
<!-- Header section end -->