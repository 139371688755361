import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TiktokService } from "../../shared/services/tiktok.service";
//import { Angulartics2 } from 'angulartics2';

@Component({
  selector: 'app-payment-excecute',
  templateUrl: './payment-excecute.component.html',
  styleUrls: ['./payment-excecute.component.scss']
})
export class PaymentExcecuteComponent implements OnInit {
  paymentId: string = "";
  payerId: string = "";
  paypalOrderId: string = "";

  constructor(private route: ActivatedRoute,
    private _ser: TiktokService,
    private router: Router,
   // private angulartics2: Angulartics2
    ) {
    this.paymentId = this.route.snapshot.queryParams.paymentId;
    this.payerId = this.route.snapshot.queryParams.PayerID;

  }

  ngOnInit() {
    this.router.navigate(['/success']);
    // const order = JSON.parse(localStorage.getItem('order'));
    // const package_id = order.package_id
    // let price = 0;

    // if (package_id === 3) {
    //   price = 15;

    // } else if (package_id === 4) {
    //   price = 25;

    // } else if (package_id === 5) { price = 40; }
    // else { price = 1; }

    // this.angulartics2.eventTrack.next({
    //   action: 'Conversion',
    //   properties: { category: 'PaypalConversion', label: 'PaypalConversion', value: price },
    // });

    // this._ser.executePayment(this.paymentId, this.payerId).subscribe(res => {
    //   if (res.status === 200) {
    //     this.paypalOrderId = res.paypal_order_id;
    //     this.router.navigate(['/success']);
    //   }
    // });
  }
}
