import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-followers-price-mobile',
  templateUrl: './followers-price-mobile.component.html',
  styleUrls: ['./followers-price-mobile.component.scss']
})
export class FollowersPriceMobileComponent implements OnInit {
  @Output() setPackageId: EventEmitter<number> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  setPackage(id:number){
    this.setPackageId.emit(id);
  }
  
}
