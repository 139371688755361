import { Component, OnInit, Inject } from '@angular/core';
import { OrderService } from "../../services/order.service";
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from "../../models/order";
import { TiktokService } from "../../shared/services/tiktok.service";
import { Observable } from 'rxjs';
import { CurrenciesService } from '../../shared/services/currencies.service';
import { environment } from '../../../environments/environment';
import { PackageDetailsService } from '../../shared/services/package-details.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  order: Order;
  package: any = null;
  payment_url: string;
  imgUrl: string;
  failedProcessCC = false;
  currency: string = 'usd';
  symbol: string = "$";
  rate: number = 1;
  stripePaymentRefId: string = '';
  isPaypalFailed: boolean = false;

  constructor(private _orderSer: OrderService,
    private router: Router,
    private route: ActivatedRoute,
    private _ser: TiktokService,
    private currenciesService: CurrenciesService,
    private _packSer: PackageDetailsService) { }

  ngOnInit() {
    this.order = this._orderSer.order;
    this.package = this._packSer.package;

    if (!this.order || !this.order.order_id) {
      this.router.navigate(['/'])
    }

    if (+this.route.snapshot.queryParams.responsecode > 0) {
      this.failedProcessCC = true;
    }

    this.currenciesService.currency.subscribe(c => {
      this.currency = c.code;
      this.symbol = c.symbol;
      this.rate = c.rate;
    })

    const oId = this.route.snapshot.queryParams.orderid || this.order.order_id;

    if (oId) {
      this._ser.getCheckoutData(oId).subscribe(res => {

        if (!res || !res.data.price) {
          this.router.navigate(['/'])
          //  return;
        }
        const data = res.data;
        this.order.order_id = oId;
        this.order.user_name = data.user_name;
        this.package.amount = data.amount;
        this.package.description = data.description;
        this.package.price = data.price;
      })
    } else {
      this.router.navigate(['/'])
    }

  }

  
  getPaypalPaymentLink() {
    const CURRENCIES = ["AUD",
      "BRL",
      "CAD",  
      "CZK",
      "DKK",
      "EUR",
      "HKD",
      "HUF",
      "ILS",
      "JPY",
      "MXN",
      "TWD",
      "NZD",
      "NOK",
      "PHP",
      "PLN",
      "GBP",
      "RUB",
      "SGD",
      "SEK",
      "CHF",
      "THB",
      "USD"];

    let curr = 'USD'
    let r = 1;

    if (CURRENCIES.indexOf(this.currency.toUpperCase().trim()) > -1) {
      curr = this.currency;
      r = this.rate;
    }

    const jsonReq = {
      "order_id": this.order.order_id,
      "price": this.package.price,
      "uname": this.order.user_name,
      "email": this.order.email,
      "currency": curr,
      "rate": r,
      "description": this.package.description
    };

    const paypalparams = window.btoa(JSON.stringify(jsonReq));

    this._ser.getPaypalPaymentLink(paypalparams).subscribe(res => {
      if (res.paymentRef) {
        const json = { "paymentType": "pp", "url":  environment.instablastseoLink + '/paypal' , 'meta': paypalparams, 'src': '2' }
        const params = window.btoa(JSON.stringify(json));
        window.location.href = environment.instablastseoLink + '/pay?params=' + params;
      } else {
        this.isPaypalFailed = true;
      }
    })
  }

  getCryptoLink() {
    const jsonReq = {
      "order_id": this.order.order_id,
      "price": this.package.price,
      "uname": this.order.user_name,
      "description": this.package.description,
      "src": "2"
    };

    const cryptoparams = window.btoa(JSON.stringify(jsonReq));

    this._ser.getCryptoPaymentLink(cryptoparams).subscribe(res => {
      if (res.cryptoLink) {
        window.location.href = res.cryptoLink;
      } else {
       //this.isCreditCardFailed = true;
      }
    })
  }

  getCheckoutImg() {
    if (this.package.description.toLowerCase().includes('like')) {
      return '/assets/icons/boost-your-tiktok-video-hearts.png';
    }
    else if (this.package.description.toLowerCase().includes('views')) {
      return '/assets/icons/buy-tiktok-views-cheap.svg';
    } else {
      return '/assets/icons/tiktokfollowers.jpg';
    }
  }


}
