import { NgModule } from '@angular/core';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '../../core/core.module';

const routes: Routes = [
  { path: '', component: PrivacyPolicyComponent }
];

@NgModule({
  declarations: [PrivacyPolicyComponent],
  imports: [
    CoreModule,
    RouterModule.forChild(routes)
  ]
})
export class PrivacyModule { }
