import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-likes-price-mobile',
  templateUrl: './likes-price-mobile.component.html',
  styleUrls: ['./likes-price-mobile.component.scss']
})
export class LikesPriceMobileComponent implements OnInit {

  constructor() { }
  @Output() setPackageId: EventEmitter<number> = new EventEmitter();
  
  ngOnInit() {
  }

  setPackage(id:number){
    this.setPackageId.emit(id);
  }

}
