import { Component, OnInit, ViewChild, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { TiktokService } from "../../../shared/services/tiktok.service";
import { Video } from "../../../models/video";
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderService } from "../../../services/order.service";
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-user-order',
  templateUrl: './user-order.component.html',
  styleUrls: ['./user-order.component.scss']
})

export class UserOrderComponent implements OnInit {

  constructor(private _ser: TiktokService,
    private formBuilder: FormBuilder,
    public _orderSer: OrderService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private renderer2: Renderer2) { }

  userName: string = '';
  userNickName = '';
  userBio = '';
  userAvatar: string = '';
  isLoading: boolean = false;
  videos: Video[] = [];
  isFreeService: boolean;
  resErrorMsg: string = '';
  isFollowersPack: boolean;

  emailForm: FormGroup;
  userForm: FormGroup;
  approveForm: FormGroup;
  videoForm: FormGroup;

  // Only required when not passing the id in methods
  @ViewChild('stepper', { static: false }) private myStepper: MatStepper;

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: this.deviceService.isMobile() ? 3 : 5,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false,
    loopedSlides: this.deviceService.isMobile() ? 3 : 5,
    loop: false,
    slidesPerGroup: this.deviceService.isMobile() ? 3 : 5
  };

  ngOnInit() {

    if (!this._orderSer.package_id) {
      this.router.navigate(['/home']);
    }

    if (this._orderSer.package_id === 1 || this._orderSer.package_id === 2) {
      this.isFreeService = true
    }
    const unamePattern = "[A-Za-z0-9_.-]+";
    this.userForm = this.formBuilder.group({
      userCtrl: ['', [Validators.required, Validators.pattern(unamePattern)]]
    });

    this.approveForm = this.formBuilder.group({
      approveCtrl: [false, [Validators.required]]
    });

    this.emailForm = this.formBuilder.group({
      emailCtrl: ['', [Validators.required, Validators.email]]
    });

    this.videoForm = this.formBuilder.group({
      videoCtrl: ['']
    });

    this.isFollowersPack = this._orderSer.package_id === 2 || (this._orderSer.package_id >= 6 && this._orderSer.package_id <= 12);
  }

  findUser() {
    this.userName = "";
    this.userAvatar = "";
    this.videos = [];
    this.resErrorMsg = '';

    const uName = this.userForm.get('userCtrl').value
    if (this.userForm.get('userCtrl').invalid === true) {
      this.resErrorMsg = 'please fill user name';
      alert('please fill user name');
    }

    //this.isLoading = true;

    this.userAvatar = '';
    this._orderSer.user_id = uName
    this._orderSer.user_name = uName;
    this.userNickName = uName;
    this.userName = uName;
    this.myStepper.next();
    return;


    // this._ser.getFindUser(uName)
    //   .subscribe(res => {

    //     this.isLoading = false;

    //     if (res.status === 500) {
    //       this.userAvatar = '';
    //       this._orderSer.user_id = uName
    //       this._orderSer.user_name = uName;
    //       this.userNickName = uName;
    //       this.userName = uName;
    //       this.myStepper.next()
    //       return;
    //     }

    //     if (res.status === 404) {
    //       this.resErrorMsg = "User not found, please make sure you are writing @Your TikTok username";
    //       return;
    //     }

    //     const data = res.data;

    //     if (res.status == 201) {
    //       this.userAvatar = data.user_data.avatar;
    //       this._orderSer.user_id = data.user_data.uid
    //       this._orderSer.user_name = data.user_data.unique_id;
    //       this.userNickName = data.user_data.nickname;
    //       this.userBio = data.user_data.signature;
    //       this.videos = data.videos;
    //       this.userName = data.user_data.unique_id;
    //       this.myStepper.next();
    //       return;
    //     }

    //     this.resErrorMsg = "User not found";
        
    //   }, error => {
    //     this.resErrorMsg = "Oops! some error occurred :( please try again later."
    //     this.isLoading = false;
    //   })
  }


  approved() {
    this.approveForm.controls['approveCtrl'].patchValue(true);
    this.myStepper.next();
  }

  notApproved() {
    this.userName = "";
    this.userAvatar = "";
    this.videos = [];
    this.myStepper.reset()
  }

  videoSelected(video: Video) {
    if (this._orderSer.order.aweme_id === video.aweme_id) {
      this._orderSer.aweme_id = "";
      this.videoForm.controls['videoCtrl'].patchValue('');
    } else {
      this._orderSer.aweme_id = video.aweme_id
      this.videoForm.controls['videoCtrl'].patchValue(video.aweme_id);
    }
  }

  continue() {
        //only likes packages are here
        const video = this.videoForm.controls['videoCtrl'].value;
        if (this.videos === null || this.videos.length === 0) {
          //user need to provide link to his post
          if (video) {
            this._orderSer.aweme_id = video;
          } else {
            alert("Please paste link to your video link");
            return;
          }
        } else if (video) {
          this._orderSer.aweme_id = `https://m.tiktok.com/v/${video}.html`
        } else {
          alert("Please paste link to your video link");
          return;
        }
        
      this.myStepper.next();
  }

  checkout() {

    if (this.emailForm.get('emailCtrl').invalid === true) {
      alert("Your email does not look right");
      return;
    }

    this._orderSer.email = this.emailForm.get('emailCtrl').value;
    this.saveOrderToDB();
  }

  saveOrderToDB() {
    this._ser.setOrder(this._orderSer.order).subscribe(res => {
      if (res.status === 200) {
        this._orderSer.order_id = res.order_id;
        //localStorage.setItem('order', JSON.stringify(this._orderSer.order));

        if (this._orderSer.order.package_id === 1 || this._orderSer.order.package_id === 2) {
          this.router.navigate(['/free-offers']);
        } else {
          this.router.navigate(['/checkout']);
        }

      }
    });
  }
}
