import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Currency } from '../../models/currency';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CurrenciesService {
    public currency: BehaviorSubject<Currency> = new BehaviorSubject(initCurrency());
    public currencies: Currency[] = [];
    
    constructor(private _http: HttpClient) {
        this.getCurrencyData();
    }

    getCurrencies() {
        const url = environment.tiktokApiUrl + "currencies/";
        return this._http.get<any>(url)
    }

    getCurrencyData(code: string = '') {
        try {
            if (window.sessionStorage && sessionStorage.getItem('selectedCurrency') && code === '') {
                // sessionStorage supported 
                const data = sessionStorage.getItem('selectedCurrency');
                if (data) {
                    this.currency.next(<Currency>JSON.parse(data))
                }
            } else {
                const url = environment.tiktokApiUrl + "currencies/getCurrencyData?code=" + code;
                this._http.get<Currency>(url).subscribe(c => {
                    this.currency.next(c);
                    if (window.sessionStorage) {
                        // sessionStorage supported
                        sessionStorage.setItem("selectedCurrency", JSON.stringify(c));
                    }
                }, err => this.currency.next({ symbol: "$", code: "usd", rate: 1 }));
            }
        } catch (error) {
            this.currency.next({ symbol: "$", code: "usd", rate: 1 })
        }

    }
}

function initCurrency(): Currency {
    return { symbol: "", code: "", rate: 1 }
}
