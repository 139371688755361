import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TiktokService } from "../../shared/services/tiktok.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private _ser: TiktokService) { }

  isFormSent: boolean = false;
  form: FormGroup;

  ngOnInit() {

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      msg: ['', [Validators.required, Validators.maxLength(500)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    if (!this.form.valid) {
      alert("Please fill all form fields");
    }

    this._ser.saveContactData(this.form.value).subscribe(x => {
      if (x.status === 200) {
        this.isFormSent = true;
        this.form.reset();
      } else { console.log(x); }
    });
  }
}
