<!-- Pricing section -->
<section class="pricing-section spad pt-0 mt-4">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-8 offset-md-2 offset-lg-0">
        <div class="pricing-plan">
          <div class="pricing-title">
            <h4>Baby Pack</h4>
            <h5>100 LIKES</h5>
          </div>
          <div class="pricing-body">
            <h2>$3.99</h2>
            <ul>
              <li>High Quality</li>
              <li>No Password Required</li>
              <li>Views upgrade available
                (only on TikTokFame)</li>
              <li>Shares upgrade available</li>
              <li>One Time Fee</li>
              <li>Delivery within 1-6 hours</li>
              <li>Account is 100% safe</li>
              <li>100% money back guarantee</li>
              <li>Premium Support</li>
              <li>24/7 online support</li>
            </ul>
            <div>
            <button (click)="setPackageId(3)" type="button" class="btn btn-primary btn-lg btn-radius">Get 100
              Likes</button></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-8 offset-md-2 offset-lg-0">
        <div class="pricing-plan gold-plan">
          <div class="pricing-title">
            <h4>Beginner Pack</h4>
            <h5>500 LIKES</h5>
          </div>
          <div class="pricing-body">
            <h2>$9.99</h2>
            <ul>
              <li>High Quality</li>
              <li>No Password Required</li>
              <li>Views upgrade available
                (only on TikTokFame)</li>
              <li>Shares upgrade available</li>
              <li>One Time Fee</li>
              <li>Delivery within 1-6 hours</li>
              <li>Account is 100% safe</li>
              <li>100% money back guarantee</li>
              <li>Premium Support</li>
              <li>24/7 online support</li>
            </ul>
            <button (click)="setPackageId(4)" type="button" class="btn btn-primary btn-lg btn-radius">Get 500
              Likes</button>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-8 offset-md-2 offset-lg-0">
        <div class="pricing-plan">
          <div class="pricing-title">
            <h4>VIP Plan</h4>
            <h5>1000 LIKES</h5>
          </div>
          <div class="pricing-body">
            <h2>$15.99</h2>
            <ul>
              <li>High Quality</li>
              <li>No Password Required</li>
              <li>Views upgrade available
                (only on TikTokFame)</li>
              <li>Shares upgrade available</li>
              <li>One Time Fee</li>
              <li>Delivery within 1-6 hours</li>
              <li>Account is 100% safe</li>
              <li>100% money back guarantee</li>
              <li>Premium Support</li>
              <li>24/7 online support</li>
            </ul>
            <button (click)="setPackageId(5)" type="button" class="btn btn-primary btn-lg btn-radius">Get 1000
              Likes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Pricing section end -->