import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TiktokService } from '../../shared/services/tiktok.service';
 


@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  title = "Payement Successful ";
  sub_title1 = "Thank you!";
  sub_title2 = "";
  constructor(private _ser: TiktokService) { }
  ngOnInit() {
  }
}
