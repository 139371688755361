<!-- <div *ngIf="!isFreeService">
  <app-page-top-section [title]="title" [sub_title1]="sub_title1" [sub_title2]="sub_title2"></app-page-top-section>
</div> -->

<div class="container mt-4" [class.user-order-container]="!isFreeService"  *ngIf="userForm!==null">

  <mat-horizontal-stepper linear="true" #stepper>

    <mat-step [stepControl]="userForm">


      <div class="container mt-4">
        <form [formGroup]="userForm" class="domain-search-form  df-white-bg" (ngSubmit)="findUser()">
          <input formControlName="userCtrl" name="userName" type="text" placeholder="@Your TikTok username"
          i18n-placeholder
            class="form-control" [class.is-invalid]="resErrorMsg" required>
          <button type="submit" class="btn btn-primary btn-lg btn-radius"
            [disabled]="userForm.controls['userCtrl'].valid===false || isLoading===true">
            <div *ngIf="isLoading===false">
              <span i18n="Search user button|search user@@search-user">Search</span>
            </div>
            <div *ngIf="isLoading===true">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="sr-only">Loading...</span>
              Loading...
            </div>
          </button>
          <div *ngIf="resErrorMsg!==null && resErrorMsg!==''" class="invalid-feedback">
            {{resErrorMsg}}
          </div>
          <div
            *ngIf="userForm.controls['userCtrl'].errors?.pattern !== null &&
          userForm.controls['userCtrl'].invalid===true && (userForm.controls['userCtrl'].dirty===true || userForm.controls['userCtrl'].touched===true)"
            class="invalid-feedback">
            Usernames can only use letters, numbers, underscores and periods
          </div>
        </form>
      </div>
      <div>
        <!-- <button mat-button matStepperNext>Next</button> -->
      </div>

    </mat-step>

    <mat-step [stepControl]="approveForm">
      <form [formGroup]="approveForm" class="container mt-4 mb-4">
        <div class="row justify-content-center  text-center ">
          <div class="user-avatar align-self-center">
            <img *ngIf="userAvatar!==''" class="img-fluid fade show align-self-center" [src]="userAvatar">
            <img *ngIf="userAvatar ===''" class="img-fluid fade show avatar align-self-center">
          </div>

        </div>

        <div *ngIf="userNickName" class="row justify-content-center">
          <p class="font-weight-bold text-dark"><b>Username:</b> {{userName}}</p>
        </div>

        <!-- <div *ngIf="userBio" class="row justify-content-center">
          <p class="font-weight-bold mb-2 text-primary ">Bio: {{userBio}}</p>
        </div> -->

        <div class="mt-3 mt-lg-0 text-center">
          <div class="row justify-content-center mt-3 mb-3">
            <h5 class="font-weight-bold mb-2 text-primary"
            i18n="Step two|found account message">
              Account found! Is this your account?</h5>
          </div>
          <div class="row justify-content-center">

            <div class="col-sm-3 align-self-center mb-4">
              <button (click)="approved()" type="button" class="btn btn-primary btn-lg btn-radius"
              i18n="Step two|found account approve">YES</button>
            </div>

            <div class="col-sm-3 align-self-center mb-4">
              <button (click)="notApproved()" type="button"
                class="btn btn-outline-secondary btn-lg btn-radius"
                i18n="Step two|found account not relevant">NO</button>
            </div>
          </div>
        </div>
      </form>

    </mat-step>

    <mat-step [stepControl]="videoForm" *ngIf="!isFollowersPack">

      <div class="section videos-section">
        <div class="container mt-4" *ngIf="videos!==null && videos.length>0; else noVideosFound">
          <h5 class="text-primary font-weight-bold mb-2"
          i18n="Step three|choose one clip">Choose One of your clips</h5>
          <swiper [config]="config">
            <div *ngFor="let item of videos" class="swiper-slide">
              <a (click)=videoSelected(item)>
                <div class="card">
                  <img class="card-img-top" [src]="item.image" alt="">
                  <div class="selected-video" *ngIf="this._orderSer.aweme_id === item.aweme_id">
                    <i class="fa fa-heart"></i>
                  </div>
                </div>
              </a>
            </div>
          </swiper>
        </div>

        <ng-template #noVideosFound>
          <div class="container mt-4">
            <form novalidate [formGroup]="videoForm" class="domain-search-form df-white-bg">
              <div class="column justify-content-center text-center">
                <div>
                  <h5 class="text-primary font-weight-bold mb-2">
                      Please paste the link to your specific video
                  </h5>
                </div>
                  <div class="col-md-6 input-group pb-3 col-centered">
                      <input formControlName="videoCtrl" name="videoLink" type="text"
                          placeholder="https://vm.tiktok.com/t73smq/" class="form-control form-control-lg"
                          required>
                      <div *ngIf="videoForm.controls['videoCtrl'].value === '' &&
                      videoForm.controls['videoCtrl'].invalid===true && (videoForm.controls['videoCtrl'].dirty===true || videoForm.controls['videoCtrl'].touched===true)"
                          class="invalid-feedback">
                          Please enter your video link
                      </div>
                  </div>
              </div>
          </form>
          </div>
        </ng-template>

        <div class="row justify-content-center text-center">
          <div class="col-sm-3 align-self-center mb-4">
            <button [disabled]="videoForm.controls['videoCtrl'].valid===false" (click)="continue()" type="button"
              class="btn btn-primary btn-lg btn-radius"
              i18n="Step three|next step button">Next</button>
          </div>
          <div class="col-sm-3 align-self-center mb-4">
            <button matStepperPrevious type="button" class="btn btn-lg btn-outline-secondary btn-radius"
            i18n="Step three|back to last step">Back</button>
          </div>
        </div>
      </div>
      <div>
      </div>
    </mat-step>

    <mat-step [stepControl]="emailForm">

      <form [formGroup]="emailForm" class="container mt-4" novalidate>
        <h5 class="text-center text-primary font-weight-bold mb-2"
        i18n="Step four |enter user email">Final Step - Enter Your Email Below</h5>
        <div class="form-group">
          <input formControlName="emailCtrl" required type="email" email class="form-control" id="exampleInputEmail1"
            aria-describedby="emailHelp" placeholder="Enter email" required autocomplete="on"
            [class.is-invalid]="emailForm.controls['emailCtrl'].invalid && emailForm.controls['emailCtrl'].touched">
          <div
            *ngIf="emailForm.controls['emailCtrl'].errors?.required===true && emailForm.controls['emailCtrl'].touched===true"
            class="invalid-feedback">Email address is
            required!</div>
          <div
            *ngIf="emailForm.controls['emailCtrl'].errors?.email===true && emailForm.controls['emailCtrl'].touched===true"
            class="invalid-feedback">Email address is not
            valid!
          </div>
          <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
        </div>
        <!-- <div class="AW-Form-2117881809"></div> -->


        <div class="row justify-content-center mt-4 text-center">
          <div class="col-sm-3 align-self-center mb-4">
            <button [disabled]="emailForm.controls['emailCtrl'].valid===false" (click)="checkout()" type="button"
              class="btn btn-primary btn-lg btn-radius">Checkout</button>
          </div>
          <div class="col-sm-3 align-self-center mb-4">
            <button matStepperPrevious type="button"
            class="btn btn-lg btn-outline-secondary btn-radius"
            i18n="Step four|back to last step">Back</button>
          </div>
        </div>
      </form>

    </mat-step>



  </mat-horizontal-stepper>


</div>

<!-- Milestones section -->
<section *ngIf="isFreeService===false" class="milestones-section set-bg-milestones">
  <div class="container">
    <div class="section-title">
      <img class="section-title-icon" alt="Free TikTok Likes service">
      <h2 style="color:white">
        Results are a click away with your Top Free TikTok likes service
      </h2>
    </div>
    <div class="row milestones-top-text">
      <div class="col-lg-10">
        <p>Some would say that adding TikTok likes is for the younger audience only. But what a similar outcome
          will take over all ages when they will get feedback from friends and family about a likeable post.
        </p>
        <p>
          In fact, it will gain tremendous effect on your mood, not matter who you are. Getting free TikTok
          likes or fans will enable you to gain knowledge about yourself before moving forward to the real
          fun.
        </p>
      </div>
    </div>
  </div>
</section>