import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { TiktokService } from '../../shared/services/tiktok.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  currentYear: number=new Date().getFullYear();
  
  constructor(config: NgbCarouselConfig,
    private _ser: TiktokService) { 
    // customize default values of carousels used by this component tree
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.showNavigationArrows=true;
    config.showNavigationIndicators=true;
  }

  ngOnInit() {
  }

}
