<app-page-top-section [title]="title" [sub_title1]="sub_title1" [sub_title2]="sub_title2"></app-page-top-section>

 <app-user-order></app-user-order>

<!-- Milestones section -->
<section class="milestones-section set-bg-milestones">
  <div class="container">
    <div class="section-title">
      <img class="section-title-icon" alt="Free TikTok Likes service">
      <h2 style="color:white">
        Results are a click away with your Top Free TikTok followers service
      </h2>
    </div>
    <div class="row milestones-top-text">
      <div class="col-lg-10">
        <p>Some would say that adding TikTok followers is for the younger audience only. But what a similar outcome
          will take over all ages when they will get feedback from friends and family about a likeable post.
        </p>
        <p>
          In fact, it will gain tremendous effect on your mood, not matter who you are. Getting free TikTok
          likes or fans will enable you to gain knowledge about yourself before moving forward to the real
          fun.
        </p>
      </div>
    </div>
  </div>
</section>
<!-- Milestones section end -->

<!-- Company Expert section-->
<section class="about-section spad pt-5">
  <div class="container">
    <div class="section-title">
      <img class="section-title-icon" alt="Our TikTok Experts Team">
      <h2>Our TikTok Experts Team</h2>
    </div>
    <div class="row">
      <div class="col-lg-7 about-text">
        <h3>Our TikTok Likes And Fans Experts Has Got Everything Ready Just For You</h3>

        <p>Luckily for you, our team of tikTok likes and fans experts are here to support your needs. Our team has
          gathered the requiered knowledge and know how about TikTok likes and fans. </p>
        <p>With half a century experience in the field under our belt, we can confidently announce and guide you through
          the right path to become a bright star in the world. </p>
        <p>You should never ever pick the first TikTok likes or fans web you see, this is the actual the reason we were
          created. So whenever you're searching online for TikTok fans or likes always keep in mind, we are committed
          and truly devoted providing you all that you need to reach your profile goal. </p>

      </div>
      <div class="col-lg-5">
        <img class="img-company-expert" alt="Our TikTok likes and fans experts team">
      </div>
    </div>
  </div>
</section>
<!-- Company Expert  section end -->