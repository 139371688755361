<app-page-top-section [title]="title"></app-page-top-section>
<div class="container">
  <p><strong>This information includes:</strong></p>
  <ul>
    <li>Your name</li>
    <li>Your email</li>
    <li>Your social network order URL</li>
    <li>Additional order notes</li>
    <li>Your IP Address</li>
    <li>Time and Date of order being placed</li>
  </ul>

  <p><strong>If payment is made via PayPal, we may collect:</strong></p>
  <ul>
    <li>PayPal first name &ndash; Your PayPal account first name</li>
    <li>PayPal last name &ndash; Your PayPal account last name</li>
    <li>PayPal email address &ndash; the PayPal email id used to pay</li>
    <li>payment type &ndash; for example Instant</li>
    <li>PayPal transaction fee &ndash; the cost of processing the payment</li>
    <li>PayPal transaction ID &ndash; to help locate your payment via PayPal</li>
  </ul>

  <p>This privacy policy has been created to explain how data is collected and why the data is used. This privacy policy
    also outlines how to access, amend or request deletion of any data we hold on you.</p>
  <p>If you have any questions you can get in contact via the Contact form on the <u><a routerLink="/contact">contact
        page</a></u> or by emailing info@tiktokblaster.com</p>
  <p>&nbsp;</p>

  <p><strong>What sort of information do we collect and why?</strong></p>
  <p>When you place an order, we may collect the following data:</p>
  <ul>
    <li>Your name &ndash; To validate your name against PayPal account details and help prevent fraud or if you agree to
      promotional emails we may send you emails and use your name in the email.</li>
    <li>Your email &ndash; to send you order updates and if you agree promotional offers.</li>
    <li>Your social network URL &ndash; to process your order we require this, so we know where to deliver your order,
      this URL and the quantity of your order may be passed onto a 3rd party to process your order.</li>
    <li>Additional order notes &ndash; If you leave any order notes.</li>
    <li>Your IP Address &ndash; To help prevent fraudulent orders.</li>
    <li>Time and Date of order being placed &ndash; so we can try to keep to timescales set out.</li>
  </ul>
  <p>&nbsp;</p>
  <p>If you leave a product review or comment anywhere on this website, the following data will be collected, stored and
    displayed on the website if approved:</p>
  <ul>
    <li>Name &ndash; This will also be displayed on the website if your review/comment is approved.</li>
    <li>The review/comment &ndash; The review or comment you left for others to see.</li>
    <li>Your IP Address &ndash; This is to help prevent spam comments and reviews.</li>
    <li>The time and date &ndash; A time and date of the comment/review being left for others to see.</li>
  </ul>
  <p>&nbsp;</p>
  <p><strong>How you can get access, delete or update your data.</strong><br /> If your wish to access, delete or update
    any data we hold about you, then please get in contact using the contact form on this website or by emailing
    info@tiktokblaster.com.</p>
  <p><strong>How we secure, store and retain your data.</strong><br /> We store your data for as long as is required to
    do so, once your data is no longer needed it will be deleted.</p>
  <p><strong>Transfer of data abroad.</strong><br /> Your social network URL provided on order and what package you have
    ordered may be transferred to a 3rd party supplier abroad, this may be required to process your order.</p>
  <p><strong>Changes to privacy policy.</strong><br /> We may make changes to the privacy policy from time to time, you
    can find out when this privacy policy was last updated at the bottom of this page.</p>
  <p><strong>Cookies.</strong><br /> This website uses cookies if items/products are added to the shopping basket, a
    purchase is made and for website features such as the currency conversion widget located in the right sidebar.</p>
  <p><strong>Getting in contact.</strong><br /> You can get in contact using the contact form found on the contact page
    or by emailing info@tiktokblaster.com. We will respond to requests within thirty (30) days.</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>Terms &amp; Conditions</p>
</div>