import { Component, OnInit } from '@angular/core';
import { TiktokService } from '../../shared/services/tiktok.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OrderService } from '../../services/order.service';
import { Router } from '@angular/router';
import { Order } from '../../models/order';
import { timer, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Angulartics2 } from 'angulartics2';

@Component({
  selector: 'app-free-offers',
  templateUrl: './free-offers.component.html',
  styleUrls: ['./free-offers.component.scss']
})
export class FreeOffersComponent implements OnInit {
  device: string = '';
  offers: any[] = [];
  offersFound?: boolean = null;
  selectedOfferId: number = 0;
  statusSubscription;
  maxAllowed = 0;
  package_id = 0;
  actionDescription: any;

  constructor(private _ser: TiktokService,
    private deviceService: DeviceDetectorService,
    private _orderSer: OrderService,
    private router: Router,
    private angulartics2: Angulartics2) { }

  ngOnInit() {

    //if (!order) {
    if (!this._orderSer.order || !this._orderSer.order.order_id) {
      this._orderSer.order = { package_id: 1, order_id: 5555 }
    }
    // else {
    //   this._orderSer.order = order;
    // }

    this.package_id = this._orderSer.order.package_id;

    if (!this._orderSer.order || !this._orderSer.order.order_id) {
      this.router.navigate(['/free-tiktok-likes']);
      return;
    }

    this.getDeviceType();

    this.actionDescription = { 1: ' 50 Free Likes', 2: ' 50 Free fans', 9: '100 Free Likes' };

    this._ser.getAdsList(this._orderSer.order.order_id, this.device).
      subscribe(x => {
        if (x.status === 200 && x.list && x.list.length > 0) {
          //found offers
          this.offers = x.list;
          this.offersFound = true;
          //console.log(x.list);
        }
        else {
          //offers not found
          this.offersFound = false;
        }
      });
  }


  getDeviceType() {
    let userAgent = navigator.userAgent || navigator.vendor;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      this.device = 'android';
    } else
      if (/android/i.test(userAgent)) {
        this.device = 'android';
      } else
        if (/iPad/i.test(userAgent)) {
          this.device = 'ipad';
        } else
          if (/iPhone|iPod/i.test(userAgent)) {
            this.device = 'iphone';
          } else if (this.deviceService.isDesktop()) {
            this.device = 'desktop'
          } else {
            this.device = 'android';
          }
  }

  checkIfFreeOfferCompleted() {
    this.startCheckOfferStatuse()
  }

  startCheckOfferStatuse() {
    let expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 7 * 1000); // 7 seconds from now.
    let alertDate = new Date(expirationDate);
    alertDate.setTime(alertDate.getTime() - 3 * 1000); // 3 seconds before expiration
    let timeDuration = (expirationDate.getTime() - alertDate.getTime());
    this._startTimer(timeDuration);
  }

  private _startTimer(timeDuration) {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }

    this.statusSubscription = timer(timeDuration).subscribe((data) => {
      // make the API call, and then calculate appropriate duartion and call startTimer() again
      this._ser.checkIfFreeOfferCompleted(this._orderSer.order.order_id).pipe(delay(1000)).subscribe((x) => {
        if (x.status === 200 && x.res.isCompleated) {

          let payout: number = 0;
          if (+x.res.payout > 0) {
            payout = +x.res.payout;
            payout = Math.round(payout);
          }

          this.angulartics2.eventTrack.next({
            action: 'Conversion',
            properties: { category: 'OGAdsConversion', label: 'OGAdsConversion', value: payout },
          });
        }
        else {
          if (this.maxAllowed <= 150) {
            this.maxAllowed++;
            this.startCheckOfferStatuse();
          }
        }
      })
    })
  }
}
