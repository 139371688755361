import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-followers-pricing',
  templateUrl: './followers-pricing.component.html',
  styleUrls: ['./followers-pricing.component.scss']
})
export class FollowersPricingComponent implements OnInit {
  @Output() packageIdEmitter = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }

  setPackageId(packageId: number) {
    this.packageIdEmitter.emit(packageId);
  }

}
