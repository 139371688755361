import { Component, OnInit } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { environment } from '../environments/environment';
import { TiktokService } from './shared/services/tiktok.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private tiktokSer:TiktokService) {
      if (environment.production) {
        angulartics2GoogleAnalytics.startTracking();
      }
  }

  ngOnInit() {

  }

}
