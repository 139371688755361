import { Package } from "../../models/package";

export const packagesArr: Package[] = [
    {
      "id": 1,
      "package_type": 1,
      "amount": 50,
      "price": 0,
      "description": "Get Free 50 Likes"
    },
    {
      "id": 2,
      "package_type": 1,
      "amount": 50,
      "price": 0,
      "description": "Get Free 50 Followers"
    },
    {
      "id": 29,
      "package_type": 1,
      "amount": 1000,
      "price": 0,
      "description": "Get Free 1000 TikTok Views"
    },
    {
      "id": 30,
      "package_type": 2,
      "amount": 25,
      "price": 0.99,
      "old_price": 1.99,
      "description": "Buy 25 TikTok Likes"
    },
    {
      "id": 3,
      "package_type": 2,
      "amount": 50,
      "price": 1.25,
      "old_price": 2.99,
      "description": "Buy 50 TikTok Likes"
    },
    {
      "id": 4,
      "package_type": 2,
      "amount": 100,
      "price": 1.99,
      "old_price": 5.99,
      "description": "Buy 100 TikTok Likes"
    },
    {
      "id": 5,
      "package_type": 2,
      "amount": 250,
      "price": 2.99,
      "old_price": 11.99,
      "description": "Buy 250 TikTok Likes"
    },
    {
      "id": 6,
      "package_type": 2,
      "amount": 500,
      "price": 5.99,
      "old_price": 14.99,
      "description": "Buy 500 TikTok Likes"
    },
    {
      "id": 7,
      "package_type": 2,
      "amount": 1000,
      "price": 9.99,
      "old_price": 24.99,
      "description": "Buy 1000 TikTok Likes"
    },
    {
      "id": 8,
      "package_type": 2,
      "amount": 2500,
      "price": 21.99,
      "old_price": 49.99,
      "description": "Buy 2500 TikTok Likes"
    },
    {
      "id": 9,
      "package_type": 2,
      "amount": 5000,
      "price": 41.99,
      "old_price": 79.99,
      "description": "Buy 5000 TikTok Likes"
    },
    {
      "id": 31,
      "package_type": 2,
      "amount": 10000,
      "price": 79.99,
      "old_price": 179.99,
      "description": "Buy 10000 TikTok Likes"
    },
    {
      "id": 32,
      "package_type": 3,
      "amount": 25,
      "price": 0.99,
      "old_price": 1.99,
      "description": "Buy 25 TikTok Followers"
    },
    {
      "id": 10,
      "package_type": 3,
      "amount": 50,
      "price": 1.49,
      "old_price": 2.99,
      "description": "Buy 50 TikTok Followers"
    },
    {
      "id": 11,
      "package_type": 3,
      "amount": 100,
      "price": 2.49,
      "old_price": 5.99,
      "description": "Buy 100 TikTok Followers"
    },
    {
      "id": 12,
      "package_type": 3,
      "amount": 250,
      "price": 5.99,
      "old_price": 11.99,
      "description": "Buy 250 TikTok Followers"
    },
    {
      "id": 13,
      "package_type": 3,
      "amount": 500,
      "price": 9.99,
      "old_price": 14.99,
      "description": "Buy 500 TikTok Followers"
    },
    {
      "id": 14,
      "package_type": 3,
      "amount": 1000,
      "price": 15.99,
      "old_price": 24.99,
      "description": "Buy 1000 TikTok Followers"
    },
    {
      "id": 15,
      "package_type": 3,
      "amount": 2500,
      "price": 34.99,
      "old_price": 49.99,
      "description": "Buy 2500 TikTok Followers"
    },
    {
      "id": 16,
      "package_type": 3,
      "amount": 5000,
      "price": 64.99,
      "old_price": 79.99,
      "description": "Buy 5000 TikTok Followers"
    },
    {
      "id": 33,
      "package_type": 3,
      "amount": 25,
      "price": 124.99,
      "old_price": 169.99,
      "description": "Buy 10000 TikTok Followers"
    },
    {
      "id": 17,
      "package_type": 4,
      "amount": 500,
      "price": 0.99,
      "description": "Buy 500 TikTok Views"
    },
    {
      "id": 18,
      "package_type": 4,
      "amount": 1000,
      "price": 1.95,
      "description": "Buy 1000 TikTok Views"
    },
    {
      "id": 19,
      "package_type": 4,
      "amount": 2500,
      "price": 3.99,
      "description": "Buy 2500 TikTok Views"
    },
    {
      "id": 20,
      "package_type": 4,
      "amount": 5000,
      "price": 7.95,
      "description": "Buy 5000 TikTok Views"
    },
    {
      "id": 21,
      "package_type": 4,
      "amount": 10000,
      "price": 9.9,
      "description": "Buy 10000 TikTok Views"
    },
    {
      "id": 22,
      "package_type": 4,
      "amount": 25000,
      "price": 19.99,
      "description": "Buy 25000 TikTok Views"
    },
    {
      "id": 23,
      "package_type": 4,
      "amount": 50000,
      "price": 39.95,
      "description": "Buy 50000 TikTok Views"
    },
    {
      "id": 24,
      "package_type": 4,
      "amount": 75000,
      "price": 49.99,
      "description": "Buy 75000 TikTok Views"
    },
    {
      "id": 25,
      "package_type": 4,
      "amount": 100000,
      "price": 59.99,
      "description": "Buy 100000 TikTok Views"
    },
    {
      "id": 26,
      "package_type": 4,
      "amount": 250000,
      "price": 99.99,
      "description": "Buy 250000 TikTok Views"
    },
    {
      "id": 27,
      "package_type": 4,
      "amount": 500000,
      "price": 149.99,
      "description": "Buy 500000 TikTok Views"
    },
    {
      "id": 28,
      "package_type": 4,
      "amount": 1000000,
      "price": 199.99,
      "description": "Buy 1000000 TikTok Views"
    }
  ]