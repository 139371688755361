<app-page-top-section [title]="title" [sub_title1]="sub_title1" [sub_title2]="sub_title2"></app-page-top-section>

<!-- Faq section -->
<section class="faq-section spad">
  <div class="container">
    <div class="section-title">
      <img class="section-title-icon" alt="TikTok likes and followers faq">
      <h2>FAQ</h2>
    </div>





    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel title="How can i make a purchase?">
        <ng-template ngbPanelContent>
          We made it simple as 1-2-3 to get TikTok likes and fans. In the first step, choose the package the fits you
          first. Next, locate your account name and then choose one of the images in your account. Last, complete your
          purchase by using the highly secured payment processor - PayPal.
          <br><br>
          Your order will be processed in a few minutes, in some cases it can take up to a day.
        </ng-template>
      </ngb-panel>
      <ngb-panel title="Is your service legit?">
        <ng-template ngbPanelContent>
          Yes! Our experts has worked for months to develop and smooth UX so that you will continue to come back every
          time. If you are still not sure you are welcome to try our free package before you purchase a real one.
        </ng-template>
      </ngb-panel>

      <ngb-panel title="How can I know that this is not a scam?">
        <ng-template ngbPanelContent>
          We supply our service with our customers in mind first. After so many years we have a become a leading force.
          You can be confident that if there is any sort of issue, we will make sure to give you a full refund. All you
          need to do is contact our support and we will help you solve your issue.
        </ng-template>
      </ngb-panel>

      <ngb-panel title="Do I need my TikTok password?">
        <ng-template ngbPanelContent>
          We do not need your password no will never ask for it. If a websites asks for your password, make sure you
          change it on the spot! We are the only legit website around.
        </ng-template>
      </ngb-panel>

      <ngb-panel title="What are the payment methods that you accept?">
        <ng-template ngbPanelContent>
          At this point of time we accept only PayPal as it is the most secured payment method online. Although we are
          planning to add additional payment methods in the near future.
        </ng-template>
      </ngb-panel>

      <ngb-panel title="Are the free TikTok likes and fans are free?">
        <ng-template ngbPanelContent>
          Are the free TikTok likes and fans are free?
          Yes! Our free TikTok likes and fans package are absolutely. Keep in mind that you can enjoy the free package
          only one time. If you will try to use it again, it might not work.
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</section>
<!-- Faq section end -->