<div class="row">

  <div class="col-lg-4 col-md-8 offset-md-2 offset-lg-0  pb-4">
    <div class="pack-title baby-pack">

      <div class="title-container">
        <div class="heart-container">
          <i class="fas fa-heart"></i>
        </div>
        <div class="plan-title">
          <div>
            <h4>50 TikTok Likes</h4>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <h2>$2.99</h2>
      </div>
      <div class="payment-btn">
        <button (click)="setPackage(13)" type="button" class="btn btn-primary btn-lg btn-radius">
          Get 50 Likes</button></div>
    </div>
  </div>

  <div class="col-lg-4 col-md-8 offset-md-2 offset-lg-0  pb-4">
    <div class="pack-title baby-pack">

      <div class="title-container">
        <div class="heart-container">
          <i class="fas fa-heart"></i>
        </div>
        <div class="plan-title">
          <div>
            <h4>100 TikTok Likes</h4>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <h2>$5.99</h2>
      </div>
      <div class="payment-btn">
        <button (click)="setPackage(14)" type="button" class="btn btn-primary btn-lg btn-radius">
          Get 100 Likes</button></div>
    </div>
  </div>

  <div class="col-lg-4 col-md-8 offset-md-2 offset-lg-0  pb-4">
    <div class="pack-title baby-pack">

      <div class="title-container">
        <div class="heart-container">
          <i class="fas fa-heart"></i>
        </div>
        <div class="plan-title">
          <div>
            <h4>250 TikTok Likes</h4>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <h2>$11.99</h2>
      </div>
      <div class="payment-btn">
        <button (click)="setPackage(15)" type="button" class="btn btn-primary btn-lg btn-radius">
          Get 250 Likes</button></div>
    </div>
  </div>

  <div class="col-lg-4 col-md-8 offset-md-2 offset-lg-0  pb-4">
    <div class="pack-title baby-pack">

      <div class="title-container">
        <div class="heart-container">
          <i class="fas fa-heart"></i>
        </div>
        <div class="plan-title">
          <div>
            <h4>500 TikTok Likes</h4>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <h2>$14.99</h2>
      </div>
      <div class="payment-btn">
        <button (click)="setPackage(3)" type="button" class="btn btn-primary btn-lg btn-radius">
          Get 500 Likes</button></div>
    </div>
  </div>

  <div class="col-lg-4 col-md-8 offset-md-2 offset-lg-0 pb-4">
      <div class="pack-title pro-plan">
          <div class="title-container">
            <div class="heart-container">
              <i class="fas fa-heart"></i>
            </div>
            <div class="plan-title">
              <div>
                <h4>1000 TikTok Likes</h4>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <h2>$24.99</h2>
          </div>
          <div class="payment-btn">
            <button (click)="setPackage(4)" type="button" class="btn btn-primary btn-lg btn-radius">
              Get 1000 Likes</button></div>
        </div>
  </div>

  <div class="col-lg-4 col-md-8 offset-md-2 offset-lg-0  pb-4">
      <div class="pack-title vip-plan">
          <div class="title-container">
            <div class="heart-container">
              <i class="fas fa-heart"></i>
            </div>
            <div class="plan-title">
              <div>
                <h4>2000 TikTok Likes</h4>
              </div>
            </div>
          </div>
    
          <div class="row justify-content-center mt-3">
            <h2>$39.99</h2>
          </div>
    
          <div class="payment-btn">
            <button (click)="setPackage(5)" type="button" class="btn btn-primary btn-lg btn-radius">
              Get 2000 Likes</button></div>
        </div>
  </div>
  <div class="col-lg-4 col-md-8 offset-md-2 offset-lg-0  pb-4">
    <div class="pack-title vip-plan">
        <div class="title-container">
          <div class="heart-container">
            <i class="fas fa-heart"></i>
          </div>
          <div class="plan-title">
            <div>
              <h4>5000 TikTok Likes</h4>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <h2>$74.99</h2>
        </div>
        <div class="payment-btn">
          <button (click)="setPackage(16)" type="button" class="btn btn-primary btn-lg btn-radius">
            Get 5000 Likes</button></div>
      </div>
</div>
</div>