<!-- Page Top section -->
<section class="page-top-section set-bg">
  <div class="container">
    <h2>Checkout</h2>
    <div class="text-white">
      <p class="sub-title-text">
        Home/ Checkout
      </p>
    </div>
  </div>
</section>
<!-- Page Top section end -->
<div class="container order-details">
  <div class="row justify-content-center">
    <div class="col-sm-8">
      <div class="card text-center" *ngIf="order!==null && package!==null">   
        <h3 class="card-header">Order details</h3>
        <div class="card-body">
        
            <!-- <img class="rounded mx-auto d-block img pb-3" [src]="imgUrl" /> -->
            <img loading="lazy" class="rounded mx-auto d-block img pb-3" [src]="getCheckoutImg()" />
         
          <h5 class="card-title">User details</h5>
          <p class="card-text">User Name: {{order.user_name}}</p>

          <h5 class="card-title">Package details</h5>
          <p class="card-text">Package name: {{package.description}}</p>
          <p class="card-text">Package price: {{symbol}}{{package.price * rate | currency: 'code':''}}</p>

          <div class="row justify-content-around pt-3 ">
            <div class="col-lg-8 col-md-10 col-sm-10 border">
                <div class="pt-2 ">
                    Pay with PayPal or Credit Card
                </div>
                <div class="">
                    <a class="btn btn-lg" id="btnpay2" (click)="getPaypalPaymentLink()">
                      <img src="/assets/btn/buy-logo-large.png"
                        alt="Buy now with PayPal" />
                    </a>
                    <div *ngIf="isPaypalFailed" class="invalid-feedback" style="display: block;">
                        We currently got issues processing PayPal. Please try again in a few hours.
                    </div>
                </div>
                <div class="pt-2 pb-1">
                    <img src="/assets/btn/secure-paypal-payment.png" alt="Buy now with PayPal"
                        loading="lazy" width="325" />
                </div>
            </div>
        </div>

        <div class="row justify-content-around pt-3 ">
            <div class="col-lg-8 col-md-10 col-sm-10 border">
                <div class="pt-2">
                    Pay by most popular Cryptocurrenies and get
                    <span class="badge badge-success">30% Bonus!</span>
                </div>
                <div class="">
                    <button (click)="getCryptoLink()" class="btn btn-primary btn-lg btn-radius" id="btncryptopay">
                        Cryptocurrency
                    </button>
                </div>
                <div class="pt-2 pb-1">
                    <img src="/assets/btn/crypto-payments.svg" viewBox="auto"/>
                </div>
            </div>
        </div>

          <!-- <div>
            <a *ngIf="payment_url" [href]="payment_url" class="btn btn-lg" role="button" id="btnPay">
              <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/buy-logo-large.png"
                alt="Buy now with PayPal" />
            </a>
          </div>
          <div class="row justify-content-center">
            <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/cc-badges-ppmcvdam.png"
              alt="Buy now with PayPal" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>